import { Chunk, Point, TrackTooltip } from 'components';
import { isTouchDevice } from 'other';

import { L, TVesselTrackPoint } from 'types';
import { TBasicTrackOptions } from './helpers';

/**
 *
 */
export abstract class AbstractTrack<T extends TBasicTrackOptions> {
  public readonly options: T;
  protected isTouchDevice = isTouchDevice();
  protected points: L.CircleMarker[];
  protected segmentsVisible: L.Polyline[];
  protected tooltip: TrackTooltip;
  // To increase click tolerance a thicker transparent track is also drawn.
  protected trackHidden: L.Polyline;
  protected trackVisible: L.Polyline;

  /**/
  protected static data2points(data: TVesselTrackPoint[]): [number, number][] {
    if (!data) return [];
    return data.map(({ latitude, longitude }) => [latitude, longitude]);
  }

  /**/
  constructor(options: T) {
    this.options = options;
    this.init();
    this.drawHidden();
  }

  /**/
  protected abstract init(): void;

  /**/
  protected abstract drawLine(chunks: Chunk[]): void;

  /**/
  public remove(): void {
    this.tooltip?.remove();
    this.trackHidden?.off();
    this.trackHidden?.removeFrom(this.options.map);
  }

  /**/
  protected handleClick = (e: L.LeafletMouseEvent) => {
    const { onClick, path } = this.options;
    onClick(path.vesselId, this.getNearestPoint(e.latlng), e.latlng);
  };

  /**/
  protected handleHover = (e: L.LeafletMouseEvent): void =>
    this.options.showTooltip && !this.isTouchDevice && this.createTooltip(e);

  /**/
  protected getNearestPoint(latlng) {
    const { map, path } = this.options;
    const latlngs = AbstractTrack.data2points(path.locations);
    const segments = [];

    for (let i = 0; i < latlngs.length - 1; i++) {
      const pointToLineDistance = window.L.LineUtil.pointToSegmentDistance(
        map.latLngToLayerPoint(latlng),
        map.latLngToLayerPoint(latlngs[i]),
        map.latLngToLayerPoint(latlngs[i + 1])
      );

      segments.push({
        index: i,
        pointToLineDistance,
        segment: [latlngs[i], latlngs[i + 1]]
      });
    }

    segments.sort((a, b) =>
      a.pointToLineDistance < b.pointToLineDistance ? -1 : 1
    );
    return path.locations[segments[0].index];
  }

  /**/
  protected drawHidden(): void {
    const { map, path } = this.options;

    this.trackHidden = window.L.polyline(
      AbstractTrack.data2points(path.locations),
      {
        color: 'transparent',
        pane: 'markerPane',
        weight: isTouchDevice() ? 20 : 10
      }
    )
      .addTo(map)
      .on('click', this.handleClick)
      .on('mouseover', this.handleHover)
      .on('mouseout', this.removeTooltip);
  }

  /**/
  protected createTooltip = (e: L.LeafletMouseEvent): void => {
    const { map, path } = this.options;
    if (!('name' in path)) return;

    const point = path.locations[path.locations.length - 1];
    const data: Point = {
      flag: path.flag,
      lastUpdate: point.lastUpdate,
      name: path.name,
      speed: point.speed
    };

    this.tooltip = new TrackTooltip(map, e.latlng, data);
  };

  /**/
  protected removeTooltip = () => this.tooltip?.remove();
}
