import { AbstractTrack } from './AbstractTrack';
import { TSimpleTrackOptions } from './helpers';

/**
 *
 */
export class SimpleTrack extends AbstractTrack<TSimpleTrackOptions> {
  /**/
  protected init(): void {
    this.drawLine();
  }

  /**/
  public remove() {
    super.remove();
    this.trackVisible?.removeFrom(this.options.map);
  }

  /**/
  protected drawLine(): void {
    const { color, map, path } = this.options;
    if (!path.locations) return;

    this.trackVisible = window.L.polyline(
      SimpleTrack.data2points(path.locations),
      {
        color: color,
        weight: 2
      }
    ).addTo(map);
  }
}
